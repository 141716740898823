import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation'
import Footer from '../../footer'
import Apply from '../../apply'

import jobMobile from '../../jobMobile.jpg'
import jobJava from '../../jobJava.jpg'
import jobBg from '../../jobBg.jpg'
import jobData from '../../jobData.jpg'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
    text-align: center;
  }
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${jobBg});
  background-size: cover;
  background-position: center;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
  }
`

const Description = styled.div`
  text-align: center;
  padding: 0 0 40px;
  max-width: 1320px;
  margin: 0 auto;
  p {
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    font-family: nunitoReg, sans-serif;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`

const Requirements = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  .column {
    text-align: center;
    flex: 0 49.9%;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(31, 40, 50, 0.52) 0%, rgba(31, 40, 50, 1) 100%);
    @media (max-width: 800px) {
      flex: 0 100%;
    }
    .list {
      padding: 30px;
    }
    h2 {
      background: #2b3541;
      padding: 25px 0;
    }
    li {
      font-family: nunitoReg, sans-serif;
      padding: 10px 0;
    }
  }
`

const Jobs = styled.div`
  padding-bottom: 50px;
  .jobGrid {
    display: flex;
    overflow: hidden;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  }
  .letter {
    font-family: nunitoReg, sans-serif;
    font-size: 4em;
    transition: font-size 350ms ease-in-out;
  }
  a {
    flex: 0 33.33%;
    height: 450px;
    text-align: center;
    color: white;
    position: relative;
    background: #1f2832;
    overflow: hidden;
    transition: background 350ms ease-in-out;
    @media (max-width: 800px) {
      flex: 0 100%;
    }
    &:hover {
      background: #3789d2;
      cursor: pointer;
      .letter {
        font-size: 6em;
      }
      img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 0.2;
        transform: scale(1.3);
      }
    }
    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.1;
      transform: scale(1.1);
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: filter 350ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 450ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
        transform 850ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    span {
      display: inline-block;
      width: 100%;
      font-size: 1.6em;
      font-family: nunitoSemi, sans-serif;
    }
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />

    <Hero>
      <div className="wrapper">
        <h1>Computer Vision Engineer</h1>
      </div>
    </Hero>

    <Description>
      <h1 className="sectionHeader">Working For Splendo</h1>
      <p>
        Splendo is a technical oriented company with an open culture. We are looking for people that want to stay current with the latest technologies in order to come up with creative solutions we use in-house and together with our customers.
      </p>

      <p>
        Our goal is creating long term and maintainable projects. We do this by working with as little overhead as possible (no project managers!), close contact with our customers, and a team of people that know how to autonomously implement the best solutions across the technology stack.
        The result should be the best experiences for our end-users.
      </p>

      <p>
        This is what lets us do some of the more interesting and complex jobs out there. And above all, we have Frits our company dog, whom you can take for a walk! If this seems like something you wouldd want to be part of, come by for a coffee and we will talk about the possibilities.
      </p>
    </Description>

    <Requirements>
      <div className="column">
        <h2 className="sectionHeader">Must Haves</h2>
        <div className="list">
          <ul>
            <li>BSc/MSc/PhD in Computer Science, Artificial Intelligence, Physics, or Mathematics</li>
            <li>Strong development skills (Python/C/C++ preferred)</li>
            <li>Knowledge of Computer Vision libraries such as OpenCV and PCL</li>
            <li>Affinity with working in a high pace (startup) environment</li>
            <li>Ability to speak and write fluently in English</li>
          </ul>
        </div>
      </div>

      <div className="column">
        <h2 className="sectionHeader">Nice to Haves</h2>
        <div className="list">
          <ul>
            <li>Knowledge of deep learning libraries such as TensorFlow, Keras, and Pytorch</li>
            <li>Experience working with point cloud data and 3D reconstruction</li>
            <li>Knowledge of photogrammetry, visual odometry, mapping, and SLAM</li>
            <li>Experience with optimizing embedded systems (CUDA)</li>
            <li>Knowledge of specialized camera technologies such as hyperspectral and polarization imaging</li>
            <li>Experience with cloud computing (GCP / AWS)</li>
          </ul>
        </div>
      </div>
    </Requirements>

    <Apply />

    <Jobs>
      <div className="jobGrid">
        <Link to="/jobs/mobile">
          <div className="job">
            <img src={jobMobile} alt="Mobile Developer" />
            <div className="wrapper">
              <span className="letter">M</span>
              <span>Mobile Developer</span>
            </div>
          </div>
        </Link>

        <Link to="/jobs/java">
          <div className="job">
            <img src={jobJava} alt="Java Developer" />
            <div className="wrapper">
              <span className="letter">J</span>
              <span>Java Developer</span>
            </div>
          </div>
        </Link>

        <Link to="/jobs/data">
          <div className="job">
            <img src={jobData} alt="Data Developer" />
            <div className="wrapper">
              <span className="letter">D</span>
              <span>Machine Learning Developer</span>
            </div>
          </div>
        </Link>
      </div>
    </Jobs>

    <Footer />
  </Container>
)
